import { searchParamFromUrl } from '@components/hooks/ParamsProvider/searchParams'
import { cancerMatcherRegexp } from '@lib/utilities/cancerMatcherRegexp'
import { useRefinementList } from 'react-instantsearch'
import { conditionSubtypes } from './consts/conditionSubtypes'
import { MultiselectRefinementsList } from './PatientDetails/CheckboxRefinementList'
import { type QuickFilterItem, QuickSearchBox } from './QuickSearchBox'
import { TrialStatusFilters } from './TrialStatusFilters'

export const QuickSearch = () => {
  const selectedCondition = searchParamFromUrl('condition')
  const condition = conditionSubtypes.find(
    (c) => c.condition === selectedCondition,
  )
  const subTypes = condition?.subtypes

  const { items: geneticMarkerOptions } = useRefinementList({
    attribute: 'geneticMarkers',
    limit: 200,
  })

  const { items: diseaseStageInclOptions } = useRefinementList({
    attribute: 'diseaseStageInclusion',
  })

  const { items: diseaseTnmOptions } = useRefinementList({
    attribute: 'diseaseTNM',
  })

  const { items: lineOfTherapyInclOptions } = useRefinementList({
    attribute: 'linesOfTherapyInclusion',
  })

  const { items: priorTreatmentsInclusion } = useRefinementList({
    attribute: 'priorTreatmentsInclusion',
    sortBy: ['name:asc'],
  })

  const { items: gleasonOptions } = useRefinementList({
    attribute: 'gradeGleasonScoreInclusion',
    sortBy: ['name:asc'],
  })

  const { items: conditions } = useRefinementList({
    attribute: 'conditions',
    limit: 100,
  })
  const allSelectOptions = [
    ...geneticMarkerOptions.map((item) => {
      return {
        attribute: 'geneticMarkers',
        attributeDisplay: 'Genetic Marker',
        isSelected: item.isRefined,
        value: item.value,
      }
    }),
    ...conditions
      .filter((condition) => subTypes?.includes(condition.value))
      .map((item) => {
        return {
          attribute: 'conditions',
          attributeDisplay: 'Subtype',
          isSelected: item.isRefined,
          value: item.value,
        }
      }),
    ...gleasonOptions.map((item) => {
      return {
        attribute: 'gradeGleasonScoreInclusion',
        attributeDisplay: 'Gleason Score',
        isSelected: item.isRefined,
        value: item.value,
      }
    }),
    ...diseaseStageInclOptions.map((item) => {
      return {
        attribute: 'diseaseStageInclusion',
        attributeDisplay: 'Disease Stage',
        isSelected: item.isRefined,
        value: item.value,
      }
    }),
    ...diseaseTnmOptions.map((item) => {
      return {
        attribute: 'diseaseTNM',
        attributeDisplay: 'Disease TNM',
        isSelected: item.isRefined,
        value: item.value,
      }
    }),
    ...lineOfTherapyInclOptions.map((item) => {
      return {
        attribute: 'linesOfTherapyInclusion',
        attributeDisplay: 'Lines of Therapy',
        isSelected: item.isRefined,
        value: item.value,
      }
    }),
    ...priorTreatmentsInclusion.map((item) => {
      return {
        attribute: 'priorTreatmentsInclusion',
        attributeDisplay: 'Prior Treatments',
        isSelected: item.isRefined,
        value: item.value,
      }
    }),
  ].filter((item) => item.value !== 'any') as QuickFilterItem[]

  return <QuickSearchBox items={allSelectOptions} />
}
// future optimizations: https://github.com/with-power/www-withpower-com/pull/6028#pullrequestreview-1665854160
export const FilterList = () => {
  const selectedCondition = searchParamFromUrl('condition')
  const condition = conditionSubtypes.find(
    (c) => c.condition === selectedCondition,
  )
  const subTypes = condition?.subtypes

  // goal of IF block is to reorder filters for oncology trials to stress most important, in this case regex match on selected condition is more global
  if (cancerMatcherRegexp.test(selectedCondition ?? '')) {
    return (
      <>
        <div className='flex h-full flex-col gap-2 '>
          <TrialStatusFilters />
          <MultiselectRefinementsList
            attribute='diseaseStageInclusion'
            sortByFacetName={true}
            title='Stage of Disease'
            tooltip={
              <div>
                How advanced is your cancer?
                <div>
                  Stage I: The cancer has not spread beyond a localized area, to
                  lymph nodes, connective tissues, or organs.
                </div>
                <div>
                  Stage II: The cancer has grown beyond a localized area, but
                  has not spread to lymph nodes, connective tissues, or organs.
                </div>
                <div>
                  Stage III: Typically, the cancer has spread to many
                  surrounding lymph nodes.
                </div>
                <div>Stage IV: The cancer has spread to distant organs. </div>
              </div>
            }
          />
          {!!subTypes && (
            <MultiselectRefinementsList
              allowList={subTypes}
              attribute='conditions'
              reorderWhenSelected={true}
              title='Condition Subtype'
            />
          )}
          <MultiselectRefinementsList
            attribute='linesOfTherapyInclusion'
            excludesAttribute='linesOfTherapyExclusion'
            sortByFacetName={true}
            title='Previous Treatments'
            tooltip={
              <div>
                {`How many different rounds of treatment have you received
              to treat your cancer? If you have not yet received chemo or
              radiation, select "0 Prior Treatments"`}
              </div>
            }
          />
          <MultiselectRefinementsList
            attribute='geneticMarkers'
            title='Biomarkers'
            tooltip={
              <div>
                {`Filter trials based on your cancer's specific genes, receptors,
              and proteins.`}
              </div>
            }
          />
          <MultiselectRefinementsList
            attribute='priorTreatmentsInclusion'
            title='Previous Treatment Type'
          />
          <MultiselectRefinementsList
            attribute='phaseList'
            sortByFacetName={true}
            title='Trial Phase'
          />
          <MultiselectRefinementsList
            attribute='gradeGleasonScoreInclusion'
            title='Disease Grade'
            tooltip={
              <div>{`Describe the grade, or Gleason Score, for your tumor.`}</div>
            }
          />
          <MultiselectRefinementsList
            attribute='diseaseTNMInclusion'
            title='Cancer Stage'
          />
          <MultiselectRefinementsList
            attribute='interventionTypes'
            sortByFacetName={true}
            title='Administration Type'
          />
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className='flex h-full flex-col gap-2 '>
          <TrialStatusFilters />
          <MultiselectRefinementsList
            attribute='interventionTypes'
            sortByFacetName={true}
            title='Administration Type'
          />
          {!!subTypes && (
            <MultiselectRefinementsList
              allowList={subTypes}
              attribute='conditions'
              reorderWhenSelected={true}
              title='Condition Subtype'
            />
          )}
          <MultiselectRefinementsList
            attribute='phaseList'
            sortByFacetName={true}
            title='Trial Phase'
          />
          <MultiselectRefinementsList
            attribute='priorTreatmentsInclusion'
            title='Previous Treatment Type'
          />
          <MultiselectRefinementsList
            attribute='linesOfTherapyInclusion'
            excludesAttribute='linesOfTherapyExclusion'
            sortByFacetName={true}
            title='Previous Treatments'
            tooltip={
              <div>
                {`How many different rounds of treatment have you received
              to treat your cancer? If you have not yet received chemo or
              radiation, select "0 Prior Treatments"`}
              </div>
            }
          />
          <MultiselectRefinementsList
            attribute='geneticMarkers'
            title='Biomarkers'
            tooltip={
              <div>
                {`Filter trials based on your cancer's specific genes, receptors,
              and proteins.`}
              </div>
            }
          />
          <MultiselectRefinementsList
            attribute='diseaseStageInclusion'
            sortByFacetName={true}
            title='Stage of Disease'
            tooltip={
              <div>
                How advanced is your cancer?
                <div>
                  Stage I: The cancer has not spread beyond a localized area, to
                  lymph nodes, connective tissues, or organs.
                </div>
                <div>
                  Stage II: The cancer has grown beyond a localized area, but
                  has not spread to lymph nodes, connective tissues, or organs.
                </div>
                <div>
                  Stage III: Typically, the cancer has spread to many
                  surrounding lymph nodes.
                </div>
                <div>Stage IV: The cancer has spread to distant organs. </div>
              </div>
            }
          />
          <MultiselectRefinementsList
            attribute='gradeGleasonScoreInclusion'
            title='Disease Grade'
            tooltip={
              <div>{`Describe the grade, or Gleason Score, for your tumor.`}</div>
            }
          />
          <MultiselectRefinementsList
            attribute='diseaseTNMInclusion'
            title='Cancer Stage'
          />
        </div>
      </>
    )
  }
}
