'use client'

import { Icon } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { type TextColor, colors } from '@lib/colors/colors'
import merge from 'lodash/merge'
import { type ReactNode, useId, useState } from 'react'
import Select, {
  type ClassNamesConfig,
  type GroupBase,
  type InputProps,
  type SelectComponentsConfig,
  type StylesConfig,
  type Theme,
  components,
} from 'react-select'

import { inputBorderStyles } from '../Input'
import { CustomSelectOption } from './CustomSelectOption'

export type SelectOption = { label: string; value: string }

export type SelectStyleOverride = StylesConfig<
  {
    label: string
    value: string
  },
  boolean
>

export type SelectProps = {
  autoFocus?: boolean
  className?: string
  customComponents?: SelectComponentsConfig<
    SelectOption,
    false,
    GroupBase<SelectOption>
  >
  customTheme?: Partial<Theme>
  description?: string
  disabled?: boolean
  disableWrapper?: boolean
  error?: boolean
  icon?: IconDefinition
  iconColor?: TextColor
  instanceId?: string | number
  isClearable?: boolean
  isSearchable?: boolean
  items: SelectOption[]
  maxMenuHeight?: number
  menuPlacement?: 'auto' | 'bottom' | 'top'
  onBlur?: () => void
  placeholder?: string
  placeholderClassName?: string
  required?: boolean
  selectClassNames?: ClassNamesConfig<
    SelectOption,
    false,
    GroupBase<SelectOption>
  >
  showBorderStates?: boolean
  showCheckCircle?: boolean
}

type SelectBoxProps = SelectProps & {
  onChange: (value?: string) => void
  optionLabelClassName?: string
  selectBoxStylesOverride?: SelectStyleOverride
  value?: string
}

export const selectBoxDefaultColor = 'text-primary500'

export const selectBoxDefaultThemeOverride = (theme?: Partial<Theme>) =>
  merge(
    {
      colors: {
        neutral0: 'white',
        primary: 'white',
        primary25: colors.primary50,
      },
      spacing: {
        baseUnit: 2,
        controlHeight: 10,
        menuGutter: 0,
      },
    },
    theme,
  ) as Theme

export const defaultSelectBoxDefaultStyleOverride: SelectStyleOverride = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
}

export const selectBoxDefaultPlaceholder = (
  placeholder: string | undefined,
  className?: string,
): ReactNode => {
  return (
    <Text
      className={classMerge('text-neutral500', className)}
      value={placeholder ?? 'Select'}
    />
  )
}

export const SelectBox = ({
  autoFocus,
  className,
  customComponents,
  customTheme,
  description,
  disabled = false,
  disableWrapper = false,
  error,
  icon,
  iconColor = selectBoxDefaultColor,
  instanceId,
  isClearable = true,
  isSearchable = true,
  items,
  maxMenuHeight,
  menuPlacement,
  onBlur,
  onChange,
  optionLabelClassName,
  placeholder,
  placeholderClassName,
  required,
  selectBoxStylesOverride,
  selectClassNames,
  showBorderStates = false,
  showCheckCircle = false,
  value,
}: SelectBoxProps) => {
  const selectedValue = items.find((item) => item.value === value) ?? null
  const isClient = typeof window !== 'undefined'
  const [isActive, setIsActive] = useState(false)

  const wrapperClasses = classMerge(
    showBorderStates
      ? inputBorderStyles(value, error, true)
      : 'border-2 border-neutral200 hover:border-neutral300',
    isActive ? 'border-primary200 hover:border-primary300' : '',
    className,
  )
  const generatedId = useId() // https://stackoverflow.com/a/73117797

  if (disableWrapper) {
    return (
      <Select
        autoFocus={autoFocus}
        className='mx-0 my-auto w-full'
        classNames={selectClassNames}
        components={{
          IndicatorSeparator: () => null,
          ...customComponents,
        }}
        formatOptionLabel={(props) => (
          <CustomSelectOption
            className={optionLabelClassName}
            selectedValue={value}
            showCheckCircle={showCheckCircle}
            {...props}
          />
        )}
        instanceId={instanceId ?? generatedId}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={false}
        isSearchable={isSearchable}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
        menuPortalTarget={isClient ? document.body : undefined}
        menuPosition='fixed'
        name={instanceId?.toString() ?? generatedId}
        onBlur={() => {
          setIsActive(false)
          onBlur ? onBlur() : null
        }}
        onChange={(selectedOption) => onChange(selectedOption?.value)}
        onFocus={() => setIsActive(true)}
        options={items}
        placeholder={selectBoxDefaultPlaceholder(
          placeholder,
          placeholderClassName,
        )}
        required={required}
        styles={{
          ...defaultSelectBoxDefaultStyleOverride,
          ...selectBoxStylesOverride,
        }}
        theme={selectBoxDefaultThemeOverride(customTheme)}
        value={selectedValue}
      />
    )
  }

  return (
    <SelectBoxWrapper
      className={wrapperClasses}
      description={description}
      icon={icon}
      iconColor={iconColor}
    >
      <Select
        autoFocus={autoFocus}
        className='mx-0 my-auto w-full'
        classNames={selectClassNames}
        components={{
          IndicatorSeparator: () => null,
          ...customComponents,
        }}
        formatOptionLabel={(props) => (
          <CustomSelectOption
            className={optionLabelClassName}
            selectedValue={value}
            showCheckCircle={showCheckCircle}
            {...props}
          />
        )}
        instanceId={instanceId ?? generatedId}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={false}
        isSearchable={isSearchable}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
        menuPortalTarget={isClient ? document.body : undefined}
        menuPosition='fixed'
        name={instanceId?.toString() ?? generatedId}
        onBlur={() => {
          setIsActive(false)
          onBlur ? onBlur() : null
        }}
        onChange={(selectedOption) => onChange(selectedOption?.value)}
        onFocus={() => setIsActive(true)}
        options={items}
        placeholder={selectBoxDefaultPlaceholder(placeholder)}
        required={required}
        styles={{
          ...defaultSelectBoxDefaultStyleOverride,
          ...selectBoxStylesOverride,
        }}
        theme={selectBoxDefaultThemeOverride(customTheme)}
        value={selectedValue}
      />
    </SelectBoxWrapper>
  )
}

/**
 * SelectBoxWrapper is necessary to add an optional icon + label onto the selectbox.
 * We also can style the selectbox with overrides through the classNames prop
 */
export const SelectBoxWrapper = ({
  children,
  className,
  description,
  icon,
  iconColor,
}: {
  children: ReactNode
  className: string
  description?: string
  icon?: IconDefinition
  iconColor: TextColor
}) => {
  return (
    <div
      className={classMerge(
        'flex items-center rounded-lg p-1',
        className,
        'bg-white',
      )}
    >
      <div className={'w-full pl-2 pr-1'}>
        {description && (
          <Text
            className='ml-1 mt-1.5 text-neutral500'
            styleName='p-tiny'
            value={description}
          />
        )}
        <div className={classMerge('flex', 'items-center')}>
          {icon && (
            <Icon
              className={classMerge(iconColor, 'text-base', 'ml-1', 'mr-1')}
              icon={icon}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

export const NumericSelectInput = (props: InputProps<SelectOption, false>) => {
  const {
    inputMode: _inputMode,
    pattern: _pattern,
    type: _type,
    ...rest
  } = props
  // Using inputMode='numeric' in order to show the numeric keyboard on mobile
  // Not using type='number' because:
  //  - it behaves differently in chrome (blocks non-numeric characters) and safari/firefox (allow non-numeric characters). More info:
  // More info: https://css-tricks.com/finger-friendly-numerical-inputs-with-inputmode/
  //  - there seems to be a bug in react-select with firefox/safari: after typing a non-numeric character, that character gets stuck in there and users can't use the input anymore (have to refresh the page)
  return (
    <components.Input
      inputMode='numeric'
      pattern='[0-9]*'
      type='text'
      {...rest}
    />
  )
}
