import {
  geoToCoordinates,
  geoToString,
} from '@components/hooks/GeolocationProvider/convertGeoToCoords'
import { GoogleMapsContext } from '@components/hooks/GooglePlacesAutocompleteProvider'
import getPlacePredictionsFactory from '@components/hooks/GooglePlacesAutocompleteProvider/getPlacePredictions'
import useGeocodeByPlaceId from '@components/hooks/GooglePlacesAutocompleteProvider/useGeocodeByPlaceId'
import { searchParamFromUrl } from '@components/hooks/ParamsProvider/searchParams'
import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import { usePrevious } from '@components/hooks/usePrevious'
import ModalOverlay from '@components/primitives/Modal/ModalOverlay'
import type { Geolocation } from '@lib/types/Geolocation'
import debounce from 'lodash/debounce'
import pickBy from 'lodash/pickBy'
import { useSearchParams } from 'next/navigation'
import {
  type ChangeEvent,
  type MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Configure } from 'react-instantsearch'

export type Coordinates = { lat?: number; lng?: number }
export type StringCoordinates = { lat?: string | null; lng?: string | null }

interface LocationConfigurationProps {
  conditionPlaceholder?: string
  interventionPlaceholder?: string
  locationPlaceholder?: string
  placeholderText?: string
  queryCoordinates: Coordinates
  setQueryCoordinates: (coordinates: Coordinates) => void
}

// Configure the geo-search related properties as defined here
// https://www.algolia.com/doc/api-reference/search-api-parameters/
export interface ConfigureGeolocationProps {
  aroundLatLng?: string
  aroundLatLngViaIP?: boolean
  aroundPrecision?: number
  aroundRadius?: number | 'all' // meters
}

function trimCountryFromDescription(description: string) {
  return description.toString().replace(/, USA|, Canada/gi, '')
}

/**
 * Construct a <Configure /> Algolia element to use the Lat / Lng determined by
 * a Google Maps API Places search. Searching for "Houston, TX" should get the Geocoded
 * lat / lng to be used to narrow the Algolia search.
 *
 * We use URLSearchParams as the medium to pass data from selecting a Geolocated Google
 * Maps API Places result down to the React render tree and then through to Algolia.
 * This is to enforce the 1-way data binding React encourages.
 *
 * We get the geolocation from the GeolocationContext, which looks at the `lat`, `lng`,
 * and `currentLocation` query parameters in the URL.
 *
 * Algolia: https://www.algolia.com/doc/api-reference/search-api-parameters/
 * Google: https://developers.google.com/maps/documentation/javascript/places-autocomplete#place_autocomplete_service
 *
 * @see https://www.algolia.com/doc/api-reference/widgets/configure/react/
 */
export default function LocationConfiguration({
  conditionPlaceholder,
  interventionPlaceholder,
  locationPlaceholder,
  placeholderText,
  queryCoordinates,
  setQueryCoordinates,
}: LocationConfigurationProps) {
  const location = searchParamFromUrl('currentLocation')
  const [inputLocation, setInputLocation] = useState(
    locationPlaceholder ?? location ?? '',
  )
  const previousInputValue = usePrevious(inputLocation)
  const [isLocationSelected, setIsLocationSelected] = useState(false)

  const [locationPickerVisible, setLocationPickerVisible] = useState(false)
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([])
  const searchParams = useSearchParams()

  const searchCoordinates = useMemo(() => {
    return {
      location: inputLocation,
      ...queryCoordinates,
    }
  }, [inputLocation, queryCoordinates])

  useEffect(() => {
    const currentLocation = searchParamFromUrl('currentLocation') ?? ''
    setInputLocation(currentLocation)
  }, [searchParams])

  // Use contexts to hold references to the Google Services + push search state to the URL
  const { googleMapsServices } = useContext(GoogleMapsContext)
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()

  // Input-related event handlers
  const inputChangeHandler = (
    event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>,
  ) => {
    if (!locationPickerVisible) {
      setLocationPickerVisible(true)
    }
    setInputLocation(event.currentTarget.value)
    if (event.currentTarget.value === '' && previousInputValue !== '') {
      updateUrlToReflectSearchState({ currentLocation: '' })
      setIsLocationSelected(false)
    }
    getPlacePredictions(event.currentTarget.value, setPredictions)
  }
  const debouncedInputHandler = debounce(inputChangeHandler, 250, {
    leading: true,
    trailing: false,
  })

  const selectedItemHandler =
    (description: string, placeId: string) =>
    (event: MouseEvent<HTMLLIElement>) => {
      event.preventDefault()
      setLocationPickerVisible(false)
      const val = trimCountryFromDescription(description)
      setInputLocation(val)
      setIsLocationSelected(true)
      geocodeByPlaceId(placeId, updateCurrentGeolocationAndLocation(val))
    }

  // Update state function handlers
  const updateCurrentGeolocationAndLocation =
    (location: string) => (geolocation: Geolocation) => {
      setQueryCoordinates(geoToCoordinates(geolocation))
      setIsLocationSelected(true)
      updateUrlToReflectSearchState(
        pickBy({
          condition: conditionPlaceholder,
          currentLocation: location,
          query: interventionPlaceholder,
          ...geoToString(geolocation),
        }),
      )
    }
  const pickFirstOptionInList = () => {
    if (!isLocationSelected && inputLocation !== '') {
      const [firstPrediction] = predictions
      if (firstPrediction) {
        const { description, place_id } = firstPrediction
        const val = trimCountryFromDescription(description)
        setInputLocation(val)
        setIsLocationSelected(true)
        geocodeByPlaceId(place_id, updateCurrentGeolocationAndLocation(val))
      }
    }
  }

  const onClose = () => setLocationPickerVisible(false)

  // Function to return list of places from Google that will fire the callback with suggestions
  const getPlacePredictions = useCallback(
    (
      query: string,
      callback: (
        suggestions: google.maps.places.AutocompletePrediction[],
      ) => void,
    ) =>
      getPlacePredictionsFactory({
        ...googleMapsServices,
        ...searchCoordinates,
      })(query, callback),
    [searchCoordinates, googleMapsServices],
  )

  // Use Google Geocoder to get the lat / lng given the picked Google Place Id
  const geocodeByPlaceId = useGeocodeByPlaceId(googleMapsServices)

  return (
    <>
      <div className='static'>
        <input
          autoComplete='off'
          className='w-full text-ellipsis text-lg font-semibold leading-[180%] focus:outline-none'
          id='search-bar-location-input'
          onBlur={pickFirstOptionInList}
          onChange={debouncedInputHandler}
          onFocus={debouncedInputHandler}
          placeholder={placeholderText}
          type='text'
          value={inputLocation}
        />
        {/* List of items to refine given the input search box above */}
        <ul
          className='absolute left-0 z-modalSearchPanel mx-auto my-4 w-full rounded-2xl border-2 border-neutral200 bg-white py-2 md:w-96'
          hidden={!locationPickerVisible || predictions.length === 0}
        >
          {predictions.map((item) => (
            <li
              className='mx-2 my-0 flex flex-row items-center justify-between rounded-2xl bg-white px-2 py-0 hover:cursor-pointer hover:bg-neutral50'
              key={item.description}
              onClick={selectedItemHandler(
                item.description,
                item.place_id ?? '',
              )}
            >
              <div className={'m-0 p-2'}>
                {trimCountryFromDescription(item.description)}
              </div>
            </li>
          ))}
        </ul>
        <ModalOverlay hidden={!locationPickerVisible} onClick={onClose} />
      </div>
      {/* Only way to configure Algolia GeoSearch is via the "Configure" element:
          https://www.algolia.com/doc/api-reference/search-api-parameters/ */}
      <Configure {...buildConfigureGeolocationProps(searchCoordinates)} />
    </>
  )
}

// Algolia-related prop building functions
const buildConfigureGeolocationProps = (coordinates: Coordinates) => {
  const props: ConfigureGeolocationProps = {}
  if (coordinates && coordinates.lat && coordinates.lng) {
    props.aroundLatLng = [
      coordinates.lat.toFixed(4),
      coordinates.lng.toFixed(4),
    ].join(',')
    props.aroundLatLngViaIP = false // ensure we only geolocate via aroundLatLng
  } else {
    props.aroundLatLngViaIP = true
  }

  return props
}
