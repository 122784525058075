import {
  ATTRIBUTES_WITH_ANY,
  type SearchParam,
  searchParamFromUrl,
  serializeSearchParamValues,
} from '@components/hooks/ParamsProvider/searchParams'
import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import useIsPartnerPage from '@components/hooks/useIsPartnerPage'
import { usePrevious } from '@components/hooks/usePrevious'
import { Checkbox } from '@components/primitives/Checkbox'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { useStats } from '@lib/utilities/algolia/useStats'
import base10FloorToString from '@lib/utilities/number/base10FloorToString'
import isEqual from 'lodash/isEqual'
import { usePathname, useSearchParams } from 'next/navigation'
import { type ReactNode, useEffect, useState } from 'react'
import { useRefinementList } from 'react-instantsearch'
import { AccordionFilterGroupContainer } from '../AccordionFilterGroupContainer'

type RefinementListItem = {
  count: number
  isRefined: boolean
  label: string
  value: string
}

export const MultiselectRefinementsList = ({
  allowList = [],
  attribute,
  excludesAttribute,
  reorderWhenSelected = false,
  sortByFacetName = false,
  title,
  tooltip,
}: {
  allowList?: string[]
  attribute: SearchParam
  excludesAttribute?: SearchParam
  reorderWhenSelected?: boolean
  sortByFacetName?: boolean
  title?: string
  tooltip?: ReactNode
}) => {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const previous = usePrevious(selectedItems)
  const { nbHits } = useStats()
  const { items } = useRefinementList({
    attribute,
    limit: 100,
    // eslint-disable-next-line no-nested-ternary
    sortBy: sortByFacetName
      ? ['name:asc']
      : reorderWhenSelected
        ? ['isRefined:desc', 'count:desc', 'name:asc']
        : ['count:desc', 'name:asc'],
  })
  const totalCount = items
    .filter((item) => item.value !== 'any')
    .reduce((count, item) => item.count + count, 0)

  const [itemsToRender, setItemsToRender] = useState(
    items.filter((item) => item.value !== 'any'),
  )
  const isRefinedCount = items.filter(
    (item) => item.value !== 'any' && item.isRefined,
  ).length

  useEffect(() => {
    setItemsToRender(
      items
        .filter((item) => item.value !== 'any')
        .filter((item) =>
          allowList.length > 0 ? allowList.includes(item.value) : true,
        ),
    )
  }, [items])

  useEffect(() => {
    if (isEqual(selectedItems, previous)) {
      return
    }
  }, [selectedItems])

  useEffect(() => {
    const valuesFromUrl =
      searchParamFromUrl(attribute)?.split(',').filter(Boolean) ??
      ([] as string[])
    setSelectedItems(valuesFromUrl)
  }, [pathname, searchParams])

  if (!itemsToRender.length) return null
  if (totalCount / nbHits < 0.05 && isRefinedCount === 0) return null // Don't show if cumulative filters are less than 5% of results

  return (
    <AccordionFilterGroupContainer
      isRefinedCount={isRefinedCount}
      title={title}
      tooltip={tooltip}
    >
      {itemsToRender.slice(0, 5).map((item) => (
        <CheckboxItem
          attribute={attribute}
          excludesAttribute={excludesAttribute}
          item={item}
          key={item.label}
          selectedItems={selectedItems}
        />
      ))}
    </AccordionFilterGroupContainer>
  )
}

const CheckboxItem = ({
  attribute,
  excludesAttribute,
  item,
  selectedItems,
}: {
  attribute: SearchParam
  excludesAttribute?: SearchParam
  item: RefinementListItem
  selectedItems: string[]
}) => {
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()
  const viewingPartnerPage = useIsPartnerPage()

  const checkboxChanged = (selected: boolean) => {
    let checkboxValues =
      searchParamFromUrl(attribute)?.split(',').filter(Boolean) ??
      ([] as string[])

    selected
      ? checkboxValues.push(item.value)
      : (checkboxValues = checkboxValues.filter((val) => val !== item.value))

    const newUrlParams = {
      [attribute]: serializeSearchParamValues(checkboxValues),
    }
    if (excludesAttribute) {
      // If we have an "inverse attribute" (e.g. "exclusion criteria for this value")
      newUrlParams[excludesAttribute] =
        serializeSearchParamValues(checkboxValues)
    }

    updateUrlToReflectSearchState(newUrlParams)
  }

  return (
    <label className='group mx-2 flex gap-4 px-2 py-3 md:hover:bg-primary100'>
      <Checkbox
        checked={!!selectedItems.find((selected) => selected === item.value)}
        className={classMerge(
          'md:[&:not(:checked)]:group-hover:bg-primary300',
          viewingPartnerPage ? 'border border-neutral500' : 'border-none',
        )}
        onChange={(e) => checkboxChanged(e.target.checked)}
      />
      {/* Label text */}
      <Text
        className='flex-1 py-0 leading-[inherit] text-neutral600 md:leading-[inherit]'
        styleName='p-small'
        value={item.label}
      />
      {/* Count badge on the right */}
      {!ATTRIBUTES_WITH_ANY.includes(attribute as any) && ( // TODO, maybe we could just sum up the count of ANY and show the #!?
        <span className='inline-block self-start rounded-full bg-neutral300 px-2'>
          {base10FloorToString(item.count)}
        </span>
      )}
    </label>
  )
}
