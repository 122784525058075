import { classMerge } from '@components/utilities/classMerge'
import { type ForwardedRef, type InputHTMLAttributes, forwardRef } from 'react'

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {}

/**
 * A styled <input type="checkbox"> element.
 */
export const Checkbox = forwardRef(function Checkbox(
  { className, ...props }: CheckboxProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <input
      className={classMerge(
        "relative size-6 cursor-pointer appearance-none justify-center rounded border border-primary500 bg-white checked:bg-primary500 checked:before:absolute checked:before:inset-0 checked:before:bg-checkMarkWhite checked:before:bg-center checked:before:bg-no-repeat checked:before:content-['']",
        className,
      )}
      ref={ref}
      type='checkbox'
      {...props}
    />
  )
})
