import useIsPartnerPage from '@components/hooks/useIsPartnerPage'
import { pushSearchHistoryIntoLocalStorage } from '@lib/browsers/localStorage/searchHistory'
import Routes from '@lib/routes'
import urlWithQueryParams from '@lib/routes/helpers/urlWithQueryParams'
import { removeUndefinedKeys } from '@lib/utilities/object/removeUndefinedKeys'
import { usePathname, useRouter } from 'next/navigation'
import {
  ALL_SEARCH_PARAMS,
  type SearchParam,
  searchParamsOnWindow,
} from './searchParams'

export type SearchParamsObject = {
  [param in SearchParam]?: string
}

export const DEFAULT_FILTERS = ['hasNoPlacebo', 'overallStatus']

export const FILTERS_THAT_SHOULD_PERSIST = [
  'ageList',
  'condition',
  'currentLocation',
  'lat',
  'lng',
  ...DEFAULT_FILTERS,
]

export const DEFAULT_SEARCH_STATE = {
  overallStatus: 'Recruiting',
}

export const FILTERS_TO_CLEAR = ALL_SEARCH_PARAMS.filter(
  (param) => !FILTERS_THAT_SHOULD_PERSIST.includes(param),
)

export const FULL_FILTER_CLEAR = ALL_SEARCH_PARAMS.filter(
  (param) => !DEFAULT_FILTERS.includes(param),
)

export const useSearchUrlParameters = () => {
  const router = useRouter()
  const pathname = usePathname()
  const viewingPartnerPage = useIsPartnerPage()

  /**
   * Push an updated URL to the router, reflecting the current search state. This will cause a re-render of the trials search with the new search state.
   */
  const updateUrlToReflectSearchState = (
    paramsToPushToUrl: SearchParamsObject,
  ) => {
    const query = {
      ...searchParamsOnWindow(), // make sure that we don't lose any query parameters
      ...paramsToPushToUrl, // add the new query parameters to the url
    }

    pushSearchHistoryIntoLocalStorage(query)

    if (!pathname) {
      router.push(urlWithQueryParams(Routes.search, removeUndefinedKeys(query)))
    } else {
      // Unless we are on a partner search page or a test condition page, we should always redirect to the search page
      const viewingTestConditionPage = pathname.startsWith('/internal')
      const shouldUseCurrentPath =
        viewingTestConditionPage || viewingPartnerPage
      const url = shouldUseCurrentPath ? pathname : Routes.search
      router.push(urlWithQueryParams(url, removeUndefinedKeys(query)))
    }
  }

  const clearSearchState = () => {
    const newSearchState = FILTERS_TO_CLEAR.reduce(
      (acc, curr) => ({ ...acc, [curr]: undefined }),
      {},
    )
    updateUrlToReflectSearchState(newSearchState)
  }

  const clearAllSearchState = () => {
    const newSearchState = FULL_FILTER_CLEAR.reduce(
      (acc, curr) => ({ ...acc, [curr]: undefined }),
      {},
    )
    updateUrlToReflectSearchState(newSearchState)
  }

  return {
    clearAllSearchState,
    clearSearchState,
    updateUrlToReflectSearchState,
  }
}
