import { getAllSearchParams } from '@components/hooks/ParamsProvider/searchParams'
import {
  type SearchParamsObject,
  useSearchUrlParameters,
} from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import { usePrevious } from '@components/hooks/usePrevious'
import { Text } from '@components/primitives/Text'
import LocalStorageItem from '@lib/browsers/localStorage/LocalStorageItem'
import {
  TRIAL_SEARCH_LOCAL_STORAGE_KEY,
  isEqualDefaultSearchState,
} from '@lib/browsers/localStorage/searchHistory'
import { trackEvent } from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'
import pickBy from 'lodash/pickBy'
import { useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { filtersToCheckInCriteria } from '../TrialSummaryCard/EligibilityMatch'

type SearchHistory = {
  [key: string]: SearchParamsObject
}

/**
 * This modal has hooks into the Query params of the URL. If a user is searching for a condition, and we have a search history for them
 * for that condition, we will prompt them to apply their old search filters.
 */
export const ApplySearchHistoryPill = () => {
  const searchParams = useSearchParams()
  const [filtersShown, setFiltersShown] = useState(false)

  const { updateUrlToReflectSearchState } = useSearchUrlParameters()
  const [searchState, setSearchState] = useState(pickBy(getAllSearchParams()))
  const prevCondition = usePrevious(searchState.condition)
  const searchHistory = new LocalStorageItem(TRIAL_SEARCH_LOCAL_STORAGE_KEY)
  const history = searchHistory.getItem() as SearchHistory

  const matchingFilters: { name: string; value: string | number }[] = []
  const savedFilters =
    history && searchState?.condition
      ? history[searchState?.condition]
      : undefined

  for (const filter of filtersToCheckInCriteria) {
    if (!savedFilters) {
      break
    }
    const value = savedFilters[filter.filterName]
    if (value && typeof value === 'string') {
      const values = value.split(',')
      values.forEach((value) => {
        matchingFilters.push({
          name: filter.displayName,
          value,
        })
      })
    }
  }

  if (savedFilters?.query) {
    matchingFilters.push({
      name: 'Custom Search',
      value: savedFilters?.query,
    })
  }

  useEffect(() => {
    setSearchState(pickBy(getAllSearchParams()))
  }, [searchParams])

  const removeConditionFromSearchHistory = () => {
    const condition = searchState.condition
    if (condition) {
      searchHistory.setItem({ ...history, [condition]: undefined })
    }
  }

  useEffect(() => {
    const condition = searchState.condition
    if (!condition || condition === prevCondition || !history) {
      return // we don't want to do anything if the condition hasn't changed
    }

    const isUserSearchingForCondition = isEqualDefaultSearchState(searchState)
    const searchHistoryForCondition = history[condition]

    if (isUserSearchingForCondition && !!searchHistoryForCondition) {
      // we are on a condition search, we can prompt the user to apply their old search
      trackEvent(TrackingEvents.DIRECTORY_SEARCH_HISTORY_MODAL_VIEWED)
      setFiltersShown(true)
    }
  }, [searchState.condition])

  const applyPreviousFilters = () => {
    if (!searchState.condition) {
      return
    }
    trackEvent(TrackingEvents.DIRECTORY_SEARCH_HISTORY_APPLIED)
    updateUrlToReflectSearchState(history[searchState.condition]!)
    setFiltersShown(false)
    removeConditionFromSearchHistory()
  }

  if (!filtersShown || matchingFilters.length === 0) {
    return <></>
  }

  return (
    <div
      className='inline-block cursor-pointer rounded-lg bg-white p-1 px-3'
      onClick={applyPreviousFilters}
    >
      Reapply previous filters
      <div className='ml-1 inline-block rounded-full bg-primary50 px-2'>
        <Text
          className='text-primary400'
          styleName='p-small'
          value={matchingFilters.length}
        />
      </div>
    </div>
  )
}
