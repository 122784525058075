'use client'

import ConditionName from '@components/app/ConditionName'
import { GoogleMapsServicesProvider } from '@components/hooks/GooglePlacesAutocompleteProvider'
import { searchParamFromUrl } from '@components/hooks/ParamsProvider/searchParams'
import useBeforeUnload from '@components/hooks/useBeforeUnload'
import { algoliaReadClient } from '@lib/database/algoliaPublicClient'
import { createAlgoliaSecuredKeyClient } from '@lib/database/algoliaSecuredKeyClient'
import { getAlgoliaSearchIndex } from '@lib/database/getAlgoliaSearchIndex'
import type { TrackingSources } from '@lib/tracking/TrackingSources'
import saveUserSearch from '@modules/userSearchInstances/saveUserSearch'
import { Configure, InstantSearch } from 'react-instantsearch'
import { AlgoliaSearchStateManager } from './AlgoliaSearchStateManager'
import { SearchBarVariants } from './SearchBarVariants'
import { SearchStateListener } from './SearchStateListener'
import { TrialSearchListings } from './TrialSearchListings'

const indexName = getAlgoliaSearchIndex('trials-by-site-locations')

/**
 * Entry point for trial search. This includes the components required for connecting to Algolia,
 * and optionally including the list of results to the search. See the `<SearchResults>` component
 * for definitions of the `<TrialCardList>` and figuring out total counts for searches. Search state
 * is kept at the URL via query parameters, serialized by Algolia.
 *
 * @param options.autoFocus When "true", put focus on the first field (condition search)
 * @param options.conditionNameFallback String to use as the content in the condition search field
 * @param options.includeResults When "true", includes the list of trials found given the search context
 * @param options.trackingSource What should we attribute events to?
 */
export default function TrialSearch({
  autoFocus = true,
  conditionNameFallback,
  deferScript = false,
  includeResults = true,
  interventionPlaceholder,
  isLoading,
  locationPlaceholder,
  logoImageId,
  securedKey,
  setLoading,
  sticky = true,
  trackingSource,
  transparentSearchBackground = true,
}: {
  autoFocus?: boolean
  conditionNameFallback?: string
  deferScript?: boolean
  includeResults?: boolean
  interventionPlaceholder?: string
  isLoading?: boolean
  locationPlaceholder?: string
  logoImageId?: string
  securedKey?: string
  setLoading?: (loading: boolean) => void
  sticky?: boolean
  trackingSource: TrackingSources
  transparentSearchBackground?: boolean
}) {
  const condition = searchParamFromUrl('condition')
  const conditionName = ConditionName(condition) ?? conditionNameFallback

  useBeforeUnload(() => saveUserSearch({ type: 'tabClosed' }))
  const searchClient = securedKey
    ? createAlgoliaSecuredKeyClient(securedKey)
    : algoliaReadClient

  return (
    <GoogleMapsServicesProvider deferScript={deferScript}>
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <AlgoliaSearchStateManager />
        {/**
         * Use <Configure /> directive to specify runtime search query modifiers.
         *
         * `distinct` ensures we return distinct results by nctId
         * `facetingAfterDistinct` ensures we do count of results based on the distinct group
         *
         * @see https://www.algolia.com/doc/api-reference/widgets/configure/react/
         * @see https://www.algolia.com/doc/api-reference/api-parameters/distinct/
         * @see https://www.algolia.com/doc/api-reference/api-parameters/facetingAfterDistinct/
         */}
        <SearchStateListener setLoading={setLoading} />
        <Configure
          aroundLatLngViaIP={true}
          distinct={1}
          facetingAfterDistinct={true}
        />
        <div>
          {!isLoading && (
            <>
              <SearchBarVariants
                autoFocus={autoFocus}
                conditionName={conditionName}
                interventionPlaceholder={interventionPlaceholder}
                isSearchPage={includeResults}
                locationPlaceholder={locationPlaceholder}
                logoImageId={logoImageId}
                sticky={sticky}
                transparentSearchBackground={transparentSearchBackground}
              />
              <TrialSearchListings
                includeResults={includeResults}
                trackingSource={trackingSource}
              />
            </>
          )}
        </div>
      </InstantSearch>
    </GoogleMapsServicesProvider>
  )
}
