import type { ClinicalTrialEligibilityCriteria } from '@prisma/client'

export type EligibilityCriteriaForFormatting = Pick<
  ClinicalTrialEligibilityCriteria,
  | 'assertiveSummary'
  | 'authoringTrialProId'
  | 'criterion'
  | 'deletedAt'
  | 'id'
  | 'isInclusion'
  | 'isPreScreenerEnabled'
  | 'isMajor'
  | 'isRequiredForQualification'
  | 'isVerificationAllowed'
  | 'nctId'
  | 'overrideAt'
  | 'questionSummary'
  | 'tags'
  | 'type'
  | 'updatedAt'
>

/**
 * Determine if we should show this eligibility criterion.
 *
 * @param criterion Eligibility criterion to determine visibility
 */
export default function isVisibleCriterion({
  criterion,
  isTrialProAdded,
}: {
  criterion: Pick<
    EligibilityCriteriaForFormatting,
    'deletedAt' | 'isMajor' | 'isPreScreenerEnabled' | 'tags'
  >
  isTrialProAdded: boolean
}) {
  if (isTrialProAdded) {
    return isVisibleCriterionManagedTrial({ criterion })
  }
  return isVisibleCriterionNonManagedTrial({ criterion })
}

function isVisibleCriterionManagedTrial({
  criterion,
}: {
  criterion: Pick<
    EligibilityCriteriaForFormatting,
    'isPreScreenerEnabled' | 'deletedAt'
  >
  isUsedInMta?: boolean
}) {
  return criterion.isPreScreenerEnabled && criterion.deletedAt === null
}

function isVisibleCriterionNonManagedTrial({
  criterion,
}: {
  criterion: Pick<
    EligibilityCriteriaForFormatting,
    'deletedAt' | 'isMajor' | 'tags'
  >
}) {
  // only show criterion for non-managed that is isMajor and not deletedAt
  const isValid = isValidCriterion({ allowedTags: ['other'], criterion })
  return criterion.isMajor && criterion.deletedAt === null && isValid
}

/**
 * Determine if we should show this eligibility criteria question given tags.
 *
 * @param criterion Eligibility criterion to determine visibility
 */
export function isValidCriterion({
  allowedTags = ['other'],
  criterion,
}: {
  allowedTags?: string[]
  criterion: Pick<EligibilityCriteriaForFormatting, 'tags'>
}) {
  const loweredAllowedTags = allowedTags.map((tag) => tag.toLowerCase())
  const hasValidTags =
    criterion.tags.length === 0 ||
    criterion.tags.some((t) => loweredAllowedTags.includes(t.toLowerCase()))

  return hasValidTags
}

export function isTrialProAdded({
  eligibilityCriteria,
}: {
  eligibilityCriteria: Pick<
    ClinicalTrialEligibilityCriteria,
    'authoringTrialProId' | 'overrideAt'
  >[]
}) {
  return eligibilityCriteria.some(
    (criterion) =>
      Boolean(criterion.authoringTrialProId) || Boolean(criterion.overrideAt),
  )
}

/**
 * Determine if we should verify/answer this eligibility criterion.
 *
 * @param criterion Eligibility criterion to determine verification
 */
export function isVerifiableCriterion({
  criterion,
  isTrialProAdded,
}: {
  criterion: Pick<
    EligibilityCriteriaForFormatting,
    | 'deletedAt'
    | 'isMajor'
    | 'isPreScreenerEnabled'
    | 'isVerificationAllowed'
    | 'tags'
  >
  isTrialProAdded: boolean
}) {
  if (isTrialProAdded) {
    return isVerifiableCriterionManagedTrial({ criterion })
  }
  return isVerifiableCriterionNonManagedTrial({ criterion })
}

function isVerifiableCriterionManagedTrial({
  criterion,
}: {
  criterion: Pick<
    EligibilityCriteriaForFormatting,
    'deletedAt' | 'isPreScreenerEnabled' | 'isVerificationAllowed'
  >
}) {
  // ideally, we should only return verifiable criteria. But, we will be all-inclusive of verifiable and pre-screener enabled criteria.
  return (
    (criterion.isVerificationAllowed || criterion.isPreScreenerEnabled) &&
    criterion.deletedAt === null
  )
}

function isVerifiableCriterionNonManagedTrial({
  criterion,
}: {
  criterion: Pick<
    EligibilityCriteriaForFormatting,
    'deletedAt' | 'isMajor' | 'isVerificationAllowed' | 'tags'
  >
}) {
  // only show criterion for non-managed that is isMajor and not deletedAt
  const isValid = isValidCriterion({ allowedTags: ['other'], criterion })
  return (
    criterion.isMajor &&
    criterion.isVerificationAllowed &&
    criterion.deletedAt === null &&
    isValid
  )
}
