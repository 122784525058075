import { searchParamFromUrl } from '@components/hooks/ParamsProvider/searchParams'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { usePathname, useSearchParams } from 'next/navigation'
import { type ReactNode, useEffect, useState } from 'react'

interface PaginationControlPageLinkProps {
  currentPage: number
  linkText: ReactNode
  onClick: () => void
  pageNumber: number
}

export const PaginationControlPageLink = ({
  linkText,
  onClick,
  pageNumber,
}: PaginationControlPageLinkProps) => {
  const currentPageFromUrl = searchParamFromUrl('page')
  const [currentPage, setCurrentPage] = useState(
    parseInt(currentPageFromUrl ?? '1', 10),
  )

  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    setCurrentPage(parseInt(currentPageFromUrl ?? '1', 10))
  }, [pathname, searchParams])

  const isActive = pageNumber === currentPage

  return (
    <div key={pageNumber} onClick={onClick}>
      <Text
        className={classMerge(
          'mx-2 my-4 size-11 cursor-pointer  p-2 text-center text-neutral900',
          isActive ? 'bg-neutral400' : 'bg-neutral100',
        )}
        value={linkText}
      />
    </div>
  )
}
